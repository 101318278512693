import React from 'react';
import Layout from 'components/layout';
import Seo from 'components/seo';
import PrivacyPolicy from 'components/@termsandconditions';

function TermsAndConditionsAppPage() {
  return (
    <Layout hideHeader={true} hideDownload={true}>
      <Seo title="Pintarnya Kerja | Ketentuan Layanan" />
      <PrivacyPolicy />
    </Layout>
  );
}

export default TermsAndConditionsAppPage;
